#custom-table-container {
  display: block;
  overflow-x: auto;
  padding: 0;
  width: 100%;
  position: relative;  

}

table {
  /* display: table; */
  border: 1px solid #eee;
  background: #fff;
  -webkit-box-shadow: 0px 0px 11px -2px rgba(219, 219, 219, 1);
  -moz-box-shadow: 0px 0px 11px -2px rgba(219, 219, 219, 1);
  box-shadow: 0px 0px 11px -2px rgba(219, 219, 219, 1);
  min-width: 100%;
  border-radius: 5px;
  table-layout: fixed;
  border-collapse: collapse;
}
.top-table-row {
  height: 35px;
  width: 100%;
  display: table-row;
  border-bottom: 1px solid #eee;
}
.top-table-row td {
  color: #121716;
  font-size: 18px;
  padding: 0 7px;
  font-weight: 500;
}

tr {
  width: 100%;
  display: table-row;
  padding: 10px;
  white-space: nowrap;
}
tr:nth-child(even) {
  background-color: rgba(33, 150, 243, 0.03);
}
/* achouri */
.hovred-tr:hover {
  background-color: rgba(33, 150, 243, 0.2);
}

/* ---------------- */
.body-table-row {
  padding: 5px 0;
}
.body-table-row td {
  display: table-cell;

  padding: 5px 8px;
  font-weight: 500;
}
