.container {
  padding: 0px;
  // display: flex; align-items: center;
  & > div {
    display: inline-block;
    vertical-align: top;
  }
  background: #fff;
  & > div:first-child {
    width: calc(100% - 160px);
  }
  & > div:last-child {
    padding-top: 30px;
  }
}
@media screen and (max-width: 800px) {
  .container {
    & > div:first-child {
      display: block;
      width: 100%;
    }
    & > div:last-child {
      padding-top: 0px;
    }
  }
}
