// @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
.p-10 {
  padding: 10px;
}
.p-5 {
  padding: 5px;
}
* {box-sizing: border-box;}







input {
   border-color: #5a5b5f !important;
  border-radius: 5px ;
  padding:5px 10px 

}
.rs-picker-toggle-value {
  color: #ccc;
}
.hole-container{
  background-color: rgb(118,178,40,0.2);

}
.page-top {
  padding-top: 10px;
  background-color: #fff; display: flex; justify-content: space-around;
  align-items: center;

}
.login-container {
  width:90%; margin: 40px auto;
  text-align: center;
  max-width: 400px;
  padding:10px; border-radius: 10px;
  border: 1px solid #aaa;
  img {max-width: 90%; margin-bottom: 25px;}  min-height: 100vh;
  background-color: #fff;

}
.page-container {
   margin: 0px auto;
  max-width: 1800px;
  min-height: 100vh;

}
.page-container2 {
  margin: 10px auto;
  width: 98%;  min-height: 100vh;


}
.app-logo {max-width: 90%; margin-bottom: 25px;}
.app-logo-mobile {display: none;}
@media screen and (max-width:800px) {
  .app-logo {display: none;}
  .app-logo-mobile {display: inline-block;} 
}
// ------------ pagination -----------
.pagination {
  display: flex; align-items: center;
  li { list-style: none; cursor: pointer; padding:0; font-size: large;}
.page-item {
border:1px solid  #eee;
}
a{padding: 14px 14px;
}
.active{
  border:2px solid rgb(118,178,40,0.3); background: rgba(118,178,40);
 a{ color:#fff}
}
}